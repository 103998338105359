import styled from 'styled-components/macro';

export const Component = styled.div<{ isActive:boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ isActive }) => (isActive) ? 'block' : 'none'};
    background: var(--app-color-overflow);
    z-index: 999;
`;

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
`;

export const Title = styled.h6`
    border-bottom: 1px solid #d2d3d8;
    padding: 10px;
    font-size: 16px;
    text-align: center;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
`;

export const AppLogo = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    
    img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
`;

export const AppLink = styled.a`
    width: 100px;
    border-radius: 6px;
    background-color: var(--brand-color-1);
    display: block;
    text-decoration: none;
    color: #fff;
    padding: 8px 0;
    cursor: pointer;
    text-align: center;
    border: 1px solid var(--brand-color-1);
    font-size: 12px;
`;

export const BtnClose = styled.button`
    width: 100px;
    border-radius: 6px;
    background: none;
    color: #000;
    padding: 8px 0;
    cursor: pointer;
    border: 1px solid #000;
    font-size: 12px;
`;