import styled from 'styled-components/macro';

export const Component = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    h2 {
        max-width: 300px;
        margin-bottom: 30px;
        color: var(--text-color-secondary);
        font-size: var(--text-sz-head);
        text-align: center;
    }

    a {
        width: 140px;
        padding: var(--btn-sz-padding);
        background: var(--brand-color-1);
        border: 1px solid var(--brand-color-1);
        border-radius: var(--btn-sz-radius);
        font-size: var(--text-sz-body);
        color: var(--text-color-light);
        text-align: center;
        text-decoration: none;
    }
`;
