import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import { IFullProfile, IShortProfile, ISaveProfile, IResult } from './types';

export const profileAPI = createApi({
    reducerPath: 'profile',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Profile'],
    endpoints: (build) => ({
        getFullProfile: build.query<IFullProfile, void>({
            query: () => ({
                url: `${Entity.profile}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
            providesTags: ['Profile']
        }),
        getShortProfile: build.query<IShortProfile, void>({
            query: () => ({
                url: `${Entity.profile}/short`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
            providesTags: ['Profile']
        }),
        postProfile: build.mutation<void, ISaveProfile|FormData>({
            query: (payload) => ({
                url: `${Entity.profile}`,
                method: 'POST',
                body: payload,
                headers: { ...getDefaultHeaders(true), ...getDynamicHeaders() },
            }),
            invalidatesTags: ['Profile'],
        }),
        // <==== This is alternative method for "deleteProfile" ==============>
        // <==== because of A1 operator blocks all DELETE method types =======>
        deleteProfileAlternative: build.mutation<IResult, void>({
            query: () => ({
                url: `${Entity.profile}/delete`,
                method: 'POST',
                headers: { ...getDefaultHeaders() },
            }),
        }),
        // <==================================================================>
        deleteProfile: build.mutation<IResult, void>({
            query: () => ({
                url: `${Entity.profile}`,
                method: 'DELETE',
                headers: { ...getDefaultHeaders() },
            }),
        }),
    }),
});
