import React, { useEffect, useRef, FC, MouseEvent, KeyboardEvent } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Path } from 'routes';
import { UserStatus } from 'config/constants';
import { useApp } from 'context/AppContext';
import { getSafeString } from 'utils/getSafeString';
import { ReactComponent as IconDialogs } from 'assets/icons/menu-dialogs.svg';
import { ReactComponent as IconGallery } from 'assets/icons/menu-gallery.svg';
import { ReactComponent as IconSettings } from 'assets/icons/menu-settings.svg';
import { ReactComponent as IconFeedback } from 'assets/icons/menu-feedback.svg';
import { ReactComponent as IconBlockList } from 'assets/icons/menu-blocklist.svg';
import { ReactComponent as IconMain } from 'assets/icons/menu-main.svg';
import { ReactComponent as IconFAQ } from 'assets/icons/menu-faq.svg';
import { ReactComponent as IconOffer } from 'assets/icons/menu-offer.svg';
import { ReactComponent as IconLogin } from 'assets/icons/menu-login.svg';
import { ReactComponent as IconHobbies } from 'assets/icons/menu-hobbies.svg';
import { Nav, List, Overflow, Note, Item, ProfileLinkWrapper, ProfileData, Avatar } from './Menu.styled';

export const Menu:FC = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { menu: { active, toggle }, userStatus, userProfile, flowUrl } = useApp();

    const name = getSafeString(userProfile?.name);
    const avatar = getSafeString(userProfile?.avatar);
    const bio = getSafeString(userProfile?.bio);
    const phone = getSafeString(userProfile?.msisdn).replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');

    const menuRefElement = useRef<HTMLDivElement|null>(null);

    const isAuthorized = (userStatus === UserStatus.Authorized);
    const isShowMessageCount = userProfile.unread_messages_count > 0;

    const closeMenu = (e:MouseEvent | KeyboardEvent) => {
        if ((e.target as HTMLElement).nodeName !== 'NAV' || (e as KeyboardEvent).key === 'Escape') {
            toggle();
        }
    }

    const PrivateMenuPoints = (<>
        <Item id="menu-point-dialogs" isActive={pathname === Path.private.dialogs}>
            <Link to={Path.private.dialogs}>
                <IconDialogs/>
                { t('nav.dialogs') }
                {
                    isShowMessageCount
                    && <Note>{ userProfile.unread_messages_count }</Note>
                }
            </Link>
        </Item>
        <Item id="menu-point-gallery" isActive={pathname === Path.private.gallery}>
            <Link to={Path.private.gallery}>
                <IconGallery/>
                { t('nav.gallery') }
            </Link>
        </Item>
        <Item id="menu-point-settings" isActive={pathname === Path.private.settings}>
            <Link to={Path.private.settings}>
                <IconSettings/>
                { t('nav.settings') }
            </Link>
        </Item>
        <Item id="menu-point-blocklist" isActive={pathname === Path.private.blocklist}>
            <Link to={Path.private.blocklist}>
                <IconBlockList/>
                { t('nav.blocklist') }
            </Link>
        </Item>
        <Item id="menu-point-hobbies" isActive={pathname === Path.private.hobbies}>
            <Link to={Path.private.hobbies}>
                <IconHobbies/>
                { t('nav.hobbies') }
            </Link>
        </Item>
    </>);

    const PublicMenuPoints = (<>
        <Item id="menu-point-feedback" isActive={pathname === Path.public.feedback}>
            <Link to={Path.public.feedback}>
                <IconFeedback/>
                { t('nav.feedback') }
            </Link>
        </Item>
        <Item id="menu-point-faq" isActive={pathname === Path.public.faq}>
            <Link to={Path.public.faq}>
                <IconFAQ/>
                { t('nav.faq') }
            </Link>
        </Item>
        <Item id="menu-point-offer" isActive={pathname === Path.public.offer}>
            <Link to={Path.public.offer}>
                <IconOffer/>
                { t('nav.offer') }
            </Link>
        </Item>
    </>);

    const ProfileLink = (
        <ProfileLinkWrapper>
            <Link to={Path.private.profile}>
                <Avatar src={avatar} alt={name}/>
                <ProfileData>
                    <h6>{ name }</h6>
                    <p>{ bio }</p>
                    <p>{ phone }</p>
                </ProfileData>
            </Link>
        </ProfileLinkWrapper>
    );

    const AuthLink = (
        <Item id="menu-point-auth">
            <a href={flowUrl}>
                <IconLogin/>
                { t('nav.login') }
            </a>
        </Item>
    );

    useEffect(() => {
        if (menuRefElement.current && active) {
            menuRefElement.current?.focus();
        }
    }, [active, menuRefElement.current]);

    if (pathname === Path.private.registration) return null;

    return (
        <>
            <Nav isActive={active} onClick={closeMenu}>
                { isAuthorized && ProfileLink }
                <List>
                    <Item id="menu-point-main" isActive={pathname === Path.public.main}>
                        <Link to={Path.public.main}>
                            <IconMain/>
                            { t('nav.main') }
                        </Link>
                    </Item>
                    { isAuthorized && PrivateMenuPoints }
                    { PublicMenuPoints }
                    { !isAuthorized && AuthLink }
                </List>
            </Nav>
            <Overflow
                ref={menuRefElement}
                isActive={active}
                tabIndex={active ? 0 : 999}
                onKeyDown={closeMenu}
                onClick={closeMenu}
            />
        </>
    )
};